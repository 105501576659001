.footer {
    background: $secondary;
    padding: 20px 20px 60px;
    @include media-breakpoint-up(md) {
        padding: 40px 40px 80px;
    }
    @include media-breakpoint-up(lg) {
        padding: 80px 60px 120px;
    }
    &__content {
        section {
            padding: 20px 0;
            width: 100%;
            text-align: center;
            @include media-breakpoint-up(sm) {
                width: unset;
                text-align: left;
            }
        }
        img {
            max-width: 120px !important;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            a {
                @extend .text-light;
            }
        }
    }

    &__copy {
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(black, .5);
        p {
            margin-bottom: 0;
        }
    }
}