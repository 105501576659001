// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: 1.4;
    &.scroll-lock {
        max-height: 100%;
        overflow: hidden;
    }
}
body {
    padding-top: 85px;
    @include media-breakpoint-up(md) {
        padding-top: 140px;
    }
}

p {
    margin-bottom: 1.6rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 2rem;
    font-weight: bold;
    text-transform: capitalize;
    color: #F87302;
    font-weight: 600;
}

h1, .h1 {
    font-size: 5rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
}

h2, .h2 {
    font-size: 4.6rem;  //Laptop: 46px, Ipads: 41.4px, Mobiles: 36.8px
}

h3, .h3 {
    font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
}

h4, .h4 {
    font-size: 2.4rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
}

h5, .h5 {
    font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

a {
    text-decoration: none;
    transition: .4s;
    &:hover {
        color: rgba($primary, .8);
    }
}