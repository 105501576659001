.box {
    display: block;
    transition: transform .5s;
    &-image {
        box-shadow: 1px 2px 10px 4px rgba(#9a9a9a, .24);
        border: 12px solid white;
        height: 250px;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            height: 200px;
        }
        img {
            width: 100%;
        }
    }
    &:hover {
        transform: scale(1.1);
        text-decoration: none;
    }
}

