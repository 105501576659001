// --------------------
// VARIABLES
//---------------------

// Colors
$body-color		: #000000;
$primary        : #f87302;
$secondary      : #633812;

$headerbg       : #fff; // Header scrolled background color.

// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px,
  );
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);
