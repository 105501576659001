// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button],
.elementor-button {
    width: 100%;
    max-width: 300px;
    border: 0;
    border-radius: 5px;
    margin-bottom: 12px;
    font-size: 2rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 10px;
    white-space: normal;
    background-color: $primary;
    color: #fff;
    transition: background-color .4s ease-in-out;
    @include media-breakpoint-up(md) {
        padding: 30px;
    }
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: rgba($primary, .8);
        text-decoration: none;
    }
}
