form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        border: none;
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 2rem;
        line-height: 1;
        @include media-breakpoint-up(md) {
            padding: 20px 15px;
        }
    }

    textarea {
        height: 210px !important;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        &.error {
        }
    }
}

.screen-reader-response {
    display: none;
}
.wpcf7-not-valid-tip {
    display: block;
    margin-bottom: 20px;
    margin-top: -10px;
    color: red;
    font-size: 1.5rem;
}

.wpcf7-response-output {
    text-align: center;
    .wpcf7-validation-errors {
        color: red;
    }
}