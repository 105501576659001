header {
    padding: 15px;
    background-color: white;
    transition: .5s ease-in-out;
    z-index: 1;
    &.fixed-top {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
    }
    
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: white;
        z-index: 1000;
    }
}

.header-logo img {
    transition: width 0.2s ease-in-out;

    width: 60px;
    .scrolled & {
        width: 40px;
    }

    @include media-breakpoint-up(md) {
        width: 100px;
    }
    .scrolled & {
        @include media-breakpoint-up(md) {
            width: 60px;
        }
    }
}

.c-site-nav  {
    line-height: 1;
    display: none;
    .is-open & {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 10;
    }
    @include media-breakpoint-up(sm) {
        display: block;
    }
    #menu {
        margin: 0;
        padding: 0;
        line-height: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        
        li {
            position: relative;
            line-height: 1;
            padding: 0 16px;
            display: inline-block;
            font-weight: bold;
            text-transform: uppercase;
            a:hover {
                color: $secondary;
            }

            ul.sub-menu {
                font-size: 1.6rem;
                background: #d4d9dd;
                padding: 12px;
                @include media-breakpoint-up(sm) {
                    position: absolute;
                    top: 28px;
                    left: 0;
                    opacity: 0;
                }
                li {
                    padding: 6px 0;
                }
            }

            &.menu-item-has-children {
                &:hover {
                    >ul.sub-menu {
                        opacity: 1;
                    }
                }
            }
        }

        .is-open & {
            justify-content: center;
            flex-direction: column;
            li {
                display: block;
                padding: 12px;
                font-size: 4rem;
                ul.sub-menu {
                    margin-top: 12px;
                    border-top: 1px solid #333;
                    border-bottom: 1px solid #333;
                    li {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

.c-nav-toggle {
	position: absolute;
	top: 36px;
	right: 24px;
	display: inline-block;
	padding: 10px 0;
    line-height: 0;
    z-index: 11;
    .scrolled & {
        top: 24px;
    }
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.menu-lable,
.menu-lable::after,
.menu-lable::before {
	content: " ";
	display: block;
	width: 40px;
	height: 2px;
	background-color: $primary;
}

.menu-lable {
	position: relative;
	transition: background-color 0.5s;

	&::before,
	&:after {
		position: absolute;
		left: 0;
		transition: transform 0.2s, top 0.2s 0.2s;
	}
	&::before {
		top: -8px;
	}
	&::after {
		top: 8px;
	}

	.is-open & {
		background-color: transparent !important;
		&::before,
		&::after {
			top: 0;
			transition: top 0.2s, transform 0.2s 0.2s;
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
}